
import "./css/styles.css";
import "./css/aos.css";
import "./css/bootstrap/css/bootstrap.css";
import "./modules/main.js";
import "./modules/curator.io.js";
import "./modules/validate";
import { getAllUrlParams, uuidv4 ,test } from './modules/utilities';
import "boxicons/dist/boxicons";

require('bootstrap-icons/font/bootstrap-icons.css');
require('boxicons/css/boxicons.min.css');
require('remixicon/fonts/remixicon.css');


// global variables -------------------------------
var bodyEl = document.querySelector('body');
var detailsSection;
var contentData;
var truncateLength = 45;
var detailsSelected = null;
//-------------------------------------------------
function init(){
    console.log("webapp loaded");
    console.log(uuidv4());
    const getdata = getAllUrlParams(document.URl);
    console.log("URLvar ::: ",getdata);
    fetchDataAsync('../data/data.json');
}

function fetchDataAsync(url) {
    fetch(url)
    .then(response => response.json())
    .then(data =>{      
        contentData = data;
        setLayout();
        
    });
}
function captureUrlVar(){
    const getdata = getAllUrlParams(document.URl);
    console.log("URLvar ::: ",getdata);
}

function setLayout(){

    let tgtContainer = document.getElementById("heroServiceData");
    let tgtServContainer = document.getElementById("_services");
    detailsSection = document.getElementById('Details');
   
    for(let i=0; i<contentData.content.packages.pNames.length; i++)
    {
        const objName = contentData.content.packages.pNames[i][1];
        for(let j=0; j<contentData.content.packages[objName].length; j++)
        {
            const dataSet = contentData.content.packages[objName][j];
            tgtServContainer.appendChild(servicesection(dataSet,j,i));       
        }
       // tgtContainer.appendChild(heroService(contentData.content.packages.pNames[i][0]));
    }
    detailsSection.classList.replace("addIt","removeIt");
    aboutSection();
}

function heroService(content){
    let divCont = document.createElement('div');
        divCont.setAttribute('class','col-xl-2 col-md-4');
    let nestedDiv = document.createElement('div');
        nestedDiv.setAttribute('class','icon-box');
    let iObj = document.createElement('i');
        iObj.setAttribute('class','ri-car-washing-line');

    let nestedHdr = document.createElement('h3');
    let scrollURI = document.createElement('a');
        scrollURI.setAttribute('href','#services');
        scrollURI.innerHTML = content;
        nestedHdr.appendChild(scrollURI);
        nestedDiv.appendChild(iObj);
        nestedDiv.appendChild(nestedHdr);
        divCont.appendChild(nestedDiv);  
        
    return divCont;
}

function aboutSection(){
    let aboutTitle = document.getElementById('aboutTitle');
        aboutTitle.innerHTML = contentData.content.about.title;
    let aboutContent = document.getElementById('aboutContent');
        aboutContent.innerHTML = contentData.content.about.content;
}


function servicesection(dataObj, idx,id){
    //let truncatedDescr = dataObj.description.substring(0,truncateLength)+"[...]";
    let truncatedDescr = cutString(dataObj.description, truncateLength)+" [...]";
    let divCont = document.createElement('div');
        divCont.setAttribute('class',dataObj.class);
        divCont.setAttribute('data-aos','zoom-in');
        divCont.setAttribute('data-aos-delay',300);
    let nestedDiv = document.createElement('div');
        nestedDiv.setAttribute('class','icon-box');
    let secondNestedDiv = document.createElement('div');
        secondNestedDiv.setAttribute('class','icon');
    let iObj = document.createElement('i');
        iObj.setAttribute('class','bx bxs-car-wash');
    let nestedhdr = document.createElement('h4');
    let nestedURI = document.createElement('a');
        nestedURI.setAttribute('href','#Details');
        nestedURI.addEventListener("click", ()=>{
            getToDetails(idx,id,dataObj);
        });

        nestedURI.innerHTML = dataObj.title;
    let nestedParag = document.createElement('p');
        nestedParag.innerHTML = truncatedDescr;

        secondNestedDiv.appendChild(iObj);
        nestedDiv.appendChild(secondNestedDiv);
        nestedhdr.appendChild(nestedURI);
        nestedDiv.appendChild(nestedhdr);
        nestedDiv.appendChild(nestedParag);
        divCont.appendChild(nestedDiv);
    return divCont;
}

function getToDetails(idx,id,dataObj){
    detailsSection.classList.replace("removeIt","addIt");
    detailsSelected = idx;
    console.log("dataSet::",dataObj);
    setServiceDetailsSection(dataObj);
}

function setServiceDetailsSection(dataObj){
    console.log(dataObj.title)
    let detailsTitle = document.getElementById('serviveDetailsTitle');
        detailsTitle.innerHTML = dataObj.title;
    let rowContainer = document.getElementById('serviveDetailsRowContainer');
        rowContainer.innerHTML = '';
    let imageContainer = document.createElement('div');
        imageContainer.setAttribute('class','col-lg-6 order-1 order-lg-2');
        imageContainer.setAttribute('data-aos','fade-left');
        imageContainer.setAttribute('data-aos-delay',200);
    let illustrationImg = document.createElement('img');
        illustrationImg.setAttribute('src','./assets/img/'+dataObj.image);
        illustrationImg.setAttribute('class','img-fluid');
        illustrationImg.setAttribute('alt','dataObj.title');
    let textContainer = document.createElement('div');
        textContainer.setAttribute('class','col-lg-6 pt-4 pt-lg-0 order-3 order-lg-1 content');
        textContainer.setAttribute('data-aos','fade-right');
        textContainer.setAttribute('data-aos-delay',300);
    let textParag = document.createElement('p');
        textParag.setAttribute('class','fst-italic');
        textParag.innerHTML = dataObj.description;
    let pricingContainer = document.createElement('div');
        pricingContainer.setAttribute('class','content pricing inset');
        pricingContainer.setAttribute('data-aos','fade-left');
        pricingContainer.setAttribute('data-aos-delay',200); 
    let priceHdr = document.createElement('h3');
        priceHdr.innerHTML = dataObj.packagePricingDetails.packName;
    let priceParag = document.createElement('p');       
        priceParag.appendChild(bulletPoint(dataObj));
        pricingContainer.appendChild(priceHdr);
        pricingContainer.appendChild(priceParag);
    let breakLn = document.createElement('br');
    let note = document.createElement('sup');
        note.setAttribute('style','font-size:1.15em;')
        note.innerHTML = dataObj.packagePricingDetails.note;

        priceParag.appendChild(breakLn);
        priceParag.appendChild(note);

        textContainer.appendChild(textParag);
        textContainer.appendChild(pricingContainer);

        imageContainer.appendChild(illustrationImg);
        rowContainer.appendChild(imageContainer);       
        rowContainer.appendChild(textContainer);
}

function bulletPoint(data){
    let list = document.createElement('ul');

    for(let i=0; i<data.packagePricingDetails.pricelist.length; i++){
        let arrow = document.createElement('i');
            arrow.setAttribute('class','ri-check-double-line');
        let bullet = document.createElement('li');
            bullet.setAttribute('class','pricing')
        let textNode = document.createTextNode(data.packagePricingDetails.pricelist[i]);
            console.log(arrow);
            bullet.appendChild(arrow);
            bullet.appendChild(textNode);
            list.appendChild(bullet);
    }
    return list;
}

function cutString(s, n){
    var cut= s.indexOf(' ', n);
    if(cut== -1) return s;
    return s.substring(0, cut)
}



/** service details
 *  
 *  <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="200">
        <img src="./images/about.png" class="img-fluid" alt="">
    </div>
    <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="200">
        <h3>Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.</h3>
        <p class="fst-italic">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua.
        </p>
        <ul>
        <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
        <li><i class="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
        <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
        </ul>
        <p>
        Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident
        </p>
    </div>
 */

/** hero
       <div class="col-xl-2 col-md-4">
          <div class="icon-box">
            <i class="ri-car-washing-line"></i> //or ri-car-washing-fill
            <h3><a href="#services">Lorem Ipsum</a></h3>
          </div>
        </div>
 */

/**services
 * class="nav-link scrollto"
*   <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
        <div class="icon-box">
            <div class="icon"><i class="bx bxs-car-wash"></i></div> bx bxs-brush bx bxs-hand
            <h4><a href="">Divera don</a></h4>
            <p>Modi nostrum vel laborum. Porro fugit error sit minus sapiente sit aspernatur</p>
        </div>
    </div>
 */
//----------------------------------------------------------------------------------------------//
window.addEventListener('load', init);